import React from "react"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import AdVertical from "../components/Ads/AdVertical"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function DownloadFreeMp3Page() {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("seoKeywordsTitle")} description={t("seoKeywordsText1")} />

      <Container maxWidth="md">
        <h1 style={{ marginTop: 40 }}>
          <Trans>seoKeywordsPlaceholder</Trans>
        </h1>
        <div style={{ padding: 8 }}>
          <Grid container spacing={8}>
            {/* left col */}
            <Grid item xs={12} md={8}>
              <h2>
                <Trans>seoKeywordsTextTop</Trans>
              </h2>

              <Link
                to="/"
                className="btn-hack"
                style={{
                  padding: "22px 32px",
                  fontSize: 21,
                }}
              >
                <Trans>seoKeywordsBtnLabel</Trans> <ChevronRightIcon />
              </Link>

              <h2>
                <Trans>seoKeywordsTitle</Trans>
              </h2>

              <p>
                <Trans>seoKeywordsText1</Trans>
              </p>
              <p>
                <Trans>homeText3</Trans>
              </p>
              <div style={{ marginTop: 70 }}>
                <div className="MuiChip-root">
                  <h3 className="MuiChip-label">mp3 converter</h3>
                </div>
                <div className="MuiChip-root">
                  <h3 className="MuiChip-label">mp3 downloader</h3>
                </div>

                <div className="MuiChip-root">
                  <h3 className="MuiChip-label">musica gratis</h3>
                </div>

                <div className="MuiChip-root">
                  <h3 className="MuiChip-label">video mp4</h3>
                </div>

                <div className="MuiChip-root">
                  <h3 className="MuiChip-label">youtube mp3</h3>
                </div>

                <div className="MuiChip-root">
                  <h3 className="MuiChip-label">musica cellulare</h3>
                </div>

                <div className="MuiChip-root">
                  <h3 className="MuiChip-label">musica per android</h3>
                </div>

                <div className="MuiChip-root">
                  <h3 className="MuiChip-label">suonerie per cellulari</h3>
                </div>
              </div>
            </Grid>

            {/* right col */}

            <Grid item xs={12} md={4}>
              <AdVertical />
            </Grid>
          </Grid>
        </div>
      </Container>
    </Layout>
  )
}
